import * as React from "react";
import DateChip from "./DateChip";


function News() {
  return (
    <>
      <h3 style={{ marginLeft: "5px" }}>&#128240; News</h3>
      <p>
        <DateChip>JUN 6th, 2023</DateChip> I was invited at the{" "}
        <a href="https://www.vlaio.be/nl/events/flanders-ai-forum-230606">
          Flanders AI Forum
        </a>{" "}
        to talk about TechWolf's approach to LLMs.
      </p>
      <p>
        <DateChip>APR 17th, 2023</DateChip> We published the first call for
        papers for the 3rd edition of{" "}
        <a href="https://recsyshr.aau.dk/call-for-papers/">RecSys in HR</a>{" "}
        workshop.
      </p>
      <p>
        <DateChip>FEB 9th, 2023</DateChip> I presented my work at regional{" "}
        <a href="https://sites.google.com/view/ai4hr-pe/">
          AI for HR and Public Employment Services
        </a>{" "}
        workshop.
      </p>
      <p>
        <DateChip>NOV 28th, 2022</DateChip> One of our interns published a{" "}
        <a href="https://arxiv.org/abs/2212.08553">paper</a> on his work at
        TechWolf about the relation between job titles and skills! 👏
      </p>
      <p>
        <DateChip>SEP 22nd, 2022</DateChip> Presented my work at the RecSys
        conference in Seattle.
      </p>
      <p>
        <DateChip>AUG 27th, 2022</DateChip> Our work on negative sampling
        strategies for skill extraction got accepted to the{" "}
        <a href="https://recsyshr.aau.dk/previous-editions/seattle/accepted-papers/">
          RecSys in HR workshop
        </a>{" "}
        as part of RecSys 2022.
      </p>
      <p>
        <DateChip>SEP 17th, 2021</DateChip> Presented our JobBERT work at the
        online FEAST workshop as part of ECML-PKDD.
      </p>
      <p>
        <DateChip>JUN 30th, 2021</DateChip> The birth of JobBERT! Our work on
        job title representation learning got accepted to the{" "}
        <a href="https://feast-ecmlpkdd.github.io/archive/2021/papers/">
          International workshop on Fair, Effective And Sustainable Talent
          management using data science
         (FEAST)</a>.
      </p>
    </>
  );
}

export default News;
