import React, { useEffect, useState } from "react";
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax";

const useMatchMedia = (mediaQuery, initialValue) => {
  const [isMatching, setIsMatching] = useState(initialValue);
  useEffect(() => {
    const watcher = window.matchMedia(mediaQuery);
    setIsMatching(watcher.matches);
    const listener = (matches) => {
      setIsMatching(matches.matches);
    };
    if (watcher.addEventListener) {
      watcher.addEventListener("change", listener);
    } else {
      watcher.addListener(listener);
    }
    return () => {
      if (watcher.removeEventListener) {
        return watcher.removeEventListener("change", listener);
      } else {
        return watcher.removeListener(listener);
      }
    };
  }, [mediaQuery]);

  return isMatching;
};

function Banner() {
    const isDesktopResolution = useMatchMedia("(min-width:992px)", true);
  return (
    <>
      <ParallaxBanner
        style={{
          height: "250px",
          display: isDesktopResolution ? "block" : "none",
        }}
      >
        <ParallaxBannerLayer
          image="homepage-crop-compressed.jpg"
          speed={-20}
          expanded={true}
        />
      </ParallaxBanner>
    </>
  );
}

export default Banner;
