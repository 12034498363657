import * as React from "react";

function DateChip(props) {
  return (
    <>
      <span style={{ color: "#4f4f4f" }}>{props.children} &#8226;</span>
    </>
  );
}

export default DateChip;
