import * as React from "react";

function About() {
  return (
    <>
      <p style={{ textAlign: "justify" }}>
        &#128075; Hi, I'm Jens-Joris Decorte. Consider me an AI nerd with a
        passion for delivering value through innovation. In my role as{" "}
        <strong>Lead Researcher</strong> at{" "}
        <strong>
          <a href="https://www.techwolf.com">TechWolf</a>
        </strong>
        , I'm part of a team that brings the power of{" "}
        <strong>Natural Language Processing (NLP)</strong> and{" "}
        <strong>recommendation systems</strong> to the field of HR.
      </p>
      <p style={{ textAlign: "justify" }}>
        &#127891; As part of my role at TechWolf I am pursuing a{" "}
        <strong>PhD in NLP</strong> in collaboration with the{" "}
        <strong>
          <a href="https://ugentt2k.github.io/">Text-to-Knowledge (T2K)</a>
        </strong>{" "}
        research group. Here I'm adviced by {" "}
        <strong>
          <a href="https://tdmeeste.github.io/">Thomas Demeester</a>
        </strong>{" "}
        and{" "}
        <strong>
          <a href="http://users.atlantis.ugent.be/cdvelder/">Chris Develder</a>
        </strong>
        .
      </p>
    </>
  );
}

export default About;
