import './App.css';
import Header from "./components/Header";
import Banner from "./components/Banner";
import About from "./components/About";
import Publications from "./components/Publications";
import News from "./components/News";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";


function App() {
  return (
    <>
      <Header />
      <Container maxWidth="lg">
        <About />
      </Container>
      <Banner />
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <Publications />
          </Grid>
          <Grid item xs={12} md={4}>
            <div style={{ maxHeight: "100%", overflowY: "auto" }}>
              <News />
            </div>
          </Grid>
        </Grid>
        <br/>
      </Container>
    </>
  );
}

export default App;
