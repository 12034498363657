import * as React from 'react'
import Chip from '@mui/material/Chip'

function Publications () {
  return (
    <>
      <h3 style={{ marginLeft: '5px' }}>&#128221; Publications</h3>
      <p>
        <Chip label='2023' size='small' />{' '}
        <strong>
          <a href='https://arxiv.org/abs/2310.15636'>
            Career Path Prediction using Resume Representation Learning and
            Skill-based Matching
          </a>
        </strong>
        <br />
        <u>Jens-Joris Decorte</u>, Jeroen Van Hautte, Johannes Deleu, Chris
        Develder, Thomas Demeester
      </p>
      <p>
        <i>
          Accepted to the 3rd Workshop on Recommender Systems for Human
          Resources (RecSys in HR 2023) as part of RecSys 2023
        </i>
      </p>
      <hr style={{ width: '95%' }} />
      <p>
        <Chip label='2023' size='small' />{' '}
        <strong>
          <a href='https://arxiv.org/abs/2307.10778'>
            Extreme Multi-Label Skill Extraction Training using Large Language
            Models
          </a>
        </strong>
        <br />
        <u>Jens-Joris Decorte</u>, Severine Verlinden, Jeroen Van Hautte,
        Johannes Deleu, Chris Develder, Thomas Demeester
      </p>
      <p>
        <i>
          Accepted to the International workshop on AI for Human Resources and
          Public Employment Services (AI4HR&PES) as part of ECML-PKDD 2023
        </i>
      </p>
      <hr style={{ width: '95%' }} />
      <p>
        <Chip label='2022' size='small' />{' '}
        <strong>
          <a href='https://arxiv.org/abs/2212.08553'>
            Is it Required? Ranking the Skills Required for a Job-Title
          </a>
        </strong>
        <br />
        Sarthak Anand, <u>Jens-Joris Decorte</u>, Niels Lowie
      </p>
      <hr style={{ width: '95%' }} />
      <p>
        <Chip label='2022' size='small' />{' '}
        <strong>
          <a href='https://arxiv.org/abs/2209.05987'>
            Design of Negative Sampling Strategies for Distantly Supervised
            Skill Extraction
          </a>
        </strong>
        <br />
        <u>Jens-Joris Decorte</u>, Jeroen Van Hautte, Johannes Deleu, Chris
        Develder, Thomas Demeester
      </p>
      <p>
        <i>
          Accepted to the 2nd Workshop on Recommender Systems for Human
          Resources (RecSys in HR 2022) as part of RecSys 2022
        </i>
      </p>
      <Chip label='RecSys 2022' color='primary' size='small' />
      <hr style={{ width: '95%' }} />
      <p>
        <Chip label='2021' size='small' />{' '}
        <strong>
          <a href='https://arxiv.org/abs/2109.09605'>
            JobBERT: Understanding Job Titles through Skills
          </a>
        </strong>
        <br />
        <u>Jens-Joris Decorte</u>, Jeroen Van Hautte, Thomas Demeester, Chris
        Develder
      </p>
      <p>
        <i>
          Accepted to the International workshop on Fair, Effective And
          Sustainable Talent management using data science (FEAST) as part of
          ECML-PKDD 2021
        </i>
      </p>
      <Chip label='ECML-PKDD 2021' color='primary' size='small' />
    </>
  )
}

export default Publications
